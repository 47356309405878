@import '../variables';
.code-help {
  .tabs {
    display: flex;
  }
  .tabs-content {
    flex: 1;
    width: 100%;
    code {
      margin: 20px;
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
    }
  }
  .tabs-nav {
    display: none;
  }
  .tab-line {
    display: none;
  }
}
.dark {
  .tabs-nav {
    background-color: $tab-inactive-background-dark;
    button,
    button.btn,
    button.btn-primary {
      background-color: $tab-inactive-background-dark;
      color: $tab-inactive-color-dark;
      &:hover {
        color: $tab-active-color-dark;
        background-color: $tab-inactive-background-dark;
      }
      &.tab-active {
        background: $tab-active-background-dark;
        color: $tab-active-color-dark;
      }
    }
  }
  .tab-line {
    background-color: $tab-line-color-dark;
  }
}
.tabs-nav {
  position: relative;
  display: flex;
  flex-direction: row;
  .tab-text {
    font-weight: 600;
  }
  button,
  button.btn,
  button.btn-primary {
    border-radius: 0 !important;
    box-shadow: none;

    flex: 1;
    background-color: $tab-inactive-background;
    color: $tab-inactive-color;
    &:hover {
      color: $tab-active-color;
      background-color: $tab-inactive-background;
      box-shadow: none;
    }
    &.btn:active {
      box-shadow: none;
      background: transparent;
    }
    min-height: 34px;
    font-weight: 500;
    &.tab-active {
      font-weight: bold;
      background: $tab-active-background;
      color: $tab-active-color;
    }
  }
}

.tabs {
  &--inline {
    .tabs-nav {
      width: fit-content;
      flex: 0;
      background-color: transparent;
      button,
      button.btn,
      button.btn-primary {
        &.tab-active {
          border-bottom: 2px solid $bt-brand-primary !important;
        }
        flex: 0;
        padding: 0 10px;
        margin: 0 20px;
      }
    }
    .tab-line {
      display: none;
    }
  }
  &--transparent {
    .tabs-nav {
      background-color: transparent;
      button,
      button.btn,
      button.btn-primary {
        &:first-child {
          margin-left: 0;
        }
        background-color: transparent;
        &.tab-active {
          background-color: transparent;
        }
      }
    }
  }
}

.tab-line {
  bottom: -1px;
  display: block;
  height: 2px;
  position: absolute;
  transition: left 1s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  background-color: $tab-line-color;
}
.tab-icon {
  margin-right: $input-padding-x;
}
.tabs.pill {
  .btn.btn-tab {
    &:first-child {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
    &:last-child {
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
  .tabs-nav {
    border-radius: $border-radius;
  }
  .tab-line {
    display: none;
  }
}
.btn.btn-tab {
  &:first-child {
    border-top-left-radius: $border-radius;
  }
  &:last-child {
    border-top-right-radius: $border-radius;
  }
  color: $tab-inactive-color;
  &.tab-active {
    color: white;
  }
}

.tab-item {
  display: none;
  &.tab-active {
    display: block;
  }
}

.tab-icon {
  font-size: 1.4em;
  display: block;
}

.btn-tab {
  position: relative;
  .unread {
    position: absolute;
    right: 0;
    top: 13px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 16px;
    height: 16px;
  }
}
