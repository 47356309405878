@import "../variables";
@import "../flexbox/mixins";

.open-source-card {
	&:hover, :focus {
		text-decoration: none !important;
	}
}
.flat-panel {
	.panel {
		box-shadow: none;
		border: none;
		.panel-heading {
			background-color: transparent;
			.panel-icon {
				display: none;
			}
		}
	}

}

.panel {
	border: none !important;

	&.no-pad {
		padding: 0 !important;
		.panel-content{
			padding: 0 !important;
		}
	}
	box-shadow: 0 10px 30px rgba(30,13,38,.1),0 5px 10px rgba(30,13,38,.1) !important;
	.no-results {
		text-align: center;
		line-height: 44px;
	}
	width: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	border-radius: $border-radius;
	.panel-heading {
		h1, h2, h3, h4, h5, h6 {
			color: $panel-heading-color;
			margin: 0;
		}

		font-weight: 500;
		font-size: 1em;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: $input-padding-x;
		padding-right: $input-padding-x;
		flex: 1 1 0%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: $panel-heading-bg;
		text-transform: capitalize;
		color: $panel-heading-color;

		.title {
			color: $panel-heading-color;
			font-family: $font-family-header;
			.subtitle {
				color: $text-icon-dark;
				font-weight: 400;
				text-transform: none;
			}
		}

	}

	.panel-content {
		background-color: $panel-bg;
		padding: $input-padding-y $input-padding-x;
		border-bottom-left-radius: $border-radius !important;
		border-bottom-right-radius: $border-radius !important;
	}
	&.panel-without-heading {
		.panel-content {
			border-top-left-radius: $border-radius !important;
			border-top-right-radius: $border-radius !important;
		}
	}

	.icon {
		color:$panel-icon-color;
		&.text-white {
			color: white;
		}
	}
	.panel-icon {
		display: inline-block;
		@include flexbox();
		align-items: center;
		width: 32px;
		height: 32px;
		border-radius: 16px;
		justify-content: center;
		background-color: $panel-icon-background-color;
		border: none;
		margin-right: $input-padding-x/2;
		.icon {
			display: inline-block;
			text-align: center;
			font-size: 18px;
			line-height: 18px;
			color: $panel-icon-color;
		}
		&--disabled {
			.icon {
				color: lightgray;
				font-size: 2em;
			}
		}
		&--success {
			border: 1px solid $bt-brand-primary;
			.icon {
				color: $bt-brand-primary;
				font-size: 2em;
			}
		}
	}

	&.no-pad {

		.panel-content {
			padding: 0;

		}
	}
}

.dark {
	.panel {
		.icon {
			color: $dark-color;
		}
		.panel-content {
			background-color: $panel-bg-dark;
			.hljs-header,.chip {
				background-color: $panel-bg-darker;
			}
		}
		.panel-heading {
			h1, h2, h3 {
				color: $panel-heading-color-dark;
				margin: 0;
			}
			.title {
				color: $header-color-dark;
				.subtitle {
					color: white;
				}
			}
			color: $header-color-dark;

			background-color: $panel-heading-bg-dark;
			.ion-ios-information-circle {
				color: $panel-icon-background-color;
			}
		}
		border: none;
		color: $panel-heading-color-dark;
		a {
			color: $dark-highlight-color;
			&:hover {
				color: $dark-highlight-color !important;
				&.text-white {
					color: white !important;
				}
			}
		}
		.list-item:hover a {
			color: $dark-highlight-color !important;
		}
		.panel-icon {
			background-color: $panel-icon-background-color-dark;
			border-color: $panel-icon-background-color-dark;
			.icon {

				color: $panel-icon-color-dark;
			}
		}

		.panel {
			background-color: $panel-bg-darker;
			.panel-content {
				background-color: $panel-bg-darker
			}
		}
	}
}


a.text-white {
	&:hover {
		color: white !important;
	}
}
