@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$primary: #6837fc;
$primary400: #906af6;
$primary600: #4e25db;
$primary700: #3919b7;
$danger: #ef4d56;
$danger400: #f57c78;
$success: #27ab95;
$info: #0aaddf;
$text-icon-dark: #1a2634;
$text-icon-light-grey: rgba(157, 164, 174, 1);
$text-icon-grey: rgba(101, 109, 123, 1);
$text-icon-light: #ffffff;
$link-color: $primary;
$info-alfa-8: rgba(10, 173, 223, 0.08);
$info-alfa-24: rgba(10, 173, 223, 0.24);
$danger-alfa-8: rgba(255, 66, 75, 0.08);
$danger-alfa-16: rgba(255, 66, 75, 0.16);
$primary-alfa-8: rgba(149, 108, 255, 0.08);
$primary-alfa-16: rgba(149, 108, 255, 0.16);
$primary-alfa-24: rgba(149, 108, 255, 0.24);
$primary-alfa-32: rgba(149, 108, 255, 0.32);
$basic-alpha-8: rgba(101, 109, 123, 0.08);
$basic-alpha-16: rgba(101, 109, 123, 0.16);
$basic-alpha-24: rgba(101, 109, 123, 0.24);
$basic-alpha-32: rgba(101, 109, 123, 0.32);
$basic-alpha-48: rgba(101, 109, 123, 0.48);
$white-alpha-8: rgba(255, 255, 255, 0.08);
$white-alpha-16: rgba(255, 255, 255, 0.16);
$white-alpha-24: rgba(255, 255, 255, 0.24);
$white-alpha-32: rgba(255, 255, 255, 0.32);
$white-alpha-48: rgba(255, 255, 255, 0.48);
$black-alpha-32: rgba(0, 0, 0, 0.32);
$theme-colors: (
  primary: $primary,
  danger: $danger,
  success: $success,
  info: $info,
  // add any additional color below
);
$bg-light300: #eff1f4;
$bg-dark200: #202839;
$bg-dark100: rgba(45, 52, 67, 1);
$bg-dark300: #121827;
$bg-dark400: #15192b;
$bg-dark500: rgba(15, 20, 37, 1);

//Body
$body-text: $text-icon-dark;
$body-text-dark: white;

$border-radius-sm: 4px;
$border-radius: 6px;
$border-radius-lg: 8px;

//Buttons
$btn-line-height-xsm: 32px;
$btn-line-height-sm: 40px;
$btn-line-height: 48px;
$btn-line-height-lg: 54px;

$btn-padding-y: 0;
$btn-font-weight: 700;
$btn-border-radius: $border-radius;
$btn-font-size: 16px;
$btn-padding-x: 20px; // todo, $spacer PR should change this to $spacer*5
$btn-padding-y-sm: 0;
$btn-padding-x-sm: 16px;
$btn-font-size-sm: 14px;
$btn-padding-y-lg: 0;
$btn-padding-x-lg: 24px;
$btn-font-size-lg: 18px;
$btn-border-radius-sm: $border-radius;
$btn-border-radius-lg: $border-radius-lg;
$btn-disabled-opacity: 0.32;
$btn-hover-bg: $primary600;
$btn-hover-bg-dark: $primary400;
$btn-active-bg: $primary700;
$btn-secondary-bg: $basic-alpha-8;
$btn-secondary-bg-dark: $white-alpha-8;
$btn-secondary-hover-bg: $basic-alpha-16;
$btn-secondary-hover-bg-dark: $white-alpha-16;
$btn-secondary-active-bg: $basic-alpha-24;
$btn-secondary-active-bg-dark: $white-alpha-24;
$btn-outline-hover-bg: $primary-alfa-8;
$btn-outline-hover-bg-dark: $basic-alpha-8;
$btn-outline-focus-bg-dark: $primary-alfa-8;
$btn-outline-active-bg: $primary-alfa-16;
$btn-danger-hover: #cd384d;
$btn-danger-active: #ac2646;
$btn-border-width: 0;

//TABS
$tab-inactive-color: $body-text;
$tab-active-background: white;
$tab-inactive-background: white;
$tab-inactive-color-dark: $body-text-dark;
$tab-active-background-dark: $bg-dark100;
$tab-inactive-background-dark: $bg-dark100;

$tab-line-color: $primary;
$tab-line-color-dark: $primary;

//HR
$hr-border-color: $basic-alpha-16;
$hr-opacity: 1;
//todo: replace with bootstrap 5.3 dark mode theme
.dark hr {
  border-color: $bg-dark500;
}

//Modal
$modal-content-color: $text-icon-grey;
$modal-content-color-dark: $text-icon-light-grey;
$modal-sm: 440px;
$modal-md: 620px;
$modal-lg: 900px;
$modal-bg-dark: $bg-dark100;
$modal-border-radius: 18px;
$modal-footer-border-width: 0;
$modal-backdrop-bg: rgba(0, 0, 0, 0.870588);
$modal-header-padding-x: $spacer * 1.5;
$modal-header-padding-y: $spacer;
$modal-header-border-width: 0;
$modal-body-padding-y: $spacer * 1.5;
$modal-body-padding-x: $spacer * 1.5;
$modal-footer-margin-between: 0;

//Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1201px,
);

$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 940px,
  xl: 1140px,
  xxl: 1141px,
);

//Typography
$font-family-base: 'OpenSans', sans-serif;
$font-size-sm: 0.825rem;
$line-height-base: 1.375;
$line-height-sm: 20px;
$line-height-lg: 24px;
$line-height-xlg: 28px;
$line-height-xsm: 18px;
$line-height-xxsm: 16px;
$body-color: $text-icon-dark;
$font-sm: 14px;
$font-caption: 13px;
$font-caption-sm: 12px;
$font-caption-xs: 11px;
$font-sizes: (
  small: $font-sm,
  caption: $font-caption,
  captionSmall: $font-caption-sm,
  captionXSmall: $font-caption-xs,
);

$h1-font-size: 42px;
$h1-line-height: 46px;
$h2-font-size: 34px;
$h2-line-height: 40px;
$h3-font-size: 30px;
$h3-line-height: 40px;
$h4-font-size: 24px;
$h4-line-height: 32px;
$h5-font-size: 20px;
$h5-line-height: 30px;
$h6-font-size: 16px;
$h6-line-height: 24px;

//Input
$input-bg: #fff;
$input-bg-dark: $bg-dark200;
$input-bg-alt: #f7f7f7;
$input-color-dark: $text-icon-light-grey;
$input-border-radius: $border-radius;
$input-height: 50px;
$input-height-sm: 42px;
$input-height-xsm: 34px;
$input-height-lg: 58px;
$input-line-height: 24px;
$input-font-size: $font-size-base;
$input-font-weight: 500;
$input-border-color: $basic-alpha-16;
$input-border-color-dark: $bg-dark400;
$input-focus-border-color-dark: $primary;
$input-hover-border-color-dark: $white-alpha-8;
$input-placeholder-color: $text-icon-light-grey;
$input-padding: 12px 16px 12px 12px;
$input-padding-sm: 8px 14px 8px 12px;
$input-padding-xsm: 6px 12px;
$input-padding-lg: 16px 20px 16px 12px;
$textarea-height: 120px;
$textarea-height-sm: 100px;
$textarea-height-xsm: 86px;
$textarea-height-lg: 128px;
$input-border-highlight-color: $primary400;
$input-placeholder-color-dark: $text-icon-light-grey;

//Switch
$switch-height: 24px;
$switch-width: 41px;
$switch-border-radius: 100px;
$switch-bg: $basic-alpha-24;
$switch-bg-dark: $white-alpha-24;
$switch-hover-bg: $basic-alpha-48;
$switch-hover-bg-dark: $white-alpha-48;
$switch-disabled-bg: $basic-alpha-32;
$switch-disabled-bg-dark: $white-alpha-48;
$switch-disabled-opacity-dark: 0.48;
$switch-checked-hover-bg: $primary600;
$switcher-width: 18px;
$switch-dark-mode-height: 40px;
$switch-dark-mode-width: 72px;
$switch-dark-mode-bg: $bg-light300;
$switch-dark-mode-bg-dark: $bg-dark300;
$switcher-checked-dark-mode-bg: $bg-dark200;
$switcher-dark-mode-width: 32px;

//Checkbox
$checkbox-width: 20px;
$checkbox-border-color: rgba(101, 109, 123, 0.24);
$checkbox-border-color-dark: rgba(255, 255, 255, 0.24);
$checkbox-focus-bg: rgba(149, 108, 255, 0.08);
$checkbox-focus-border-color: $primary400;
$checkbox-hover-border-color: $primary;
$checkbox-checked-hover-border-color: $primary600;

//Alert
$alert-info-bg: $info-alfa-8;
$alert-info-border-color: $info;
$alert-danger-bg: $danger-alfa-8;
$alert-danger-border-color: $danger;
$alert-color: $text-icon-grey;
$alert-font-size: $font-sm;
$alert-line-height: $line-height-sm;
