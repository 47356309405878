#modal2 {
    .modal {
        z-index: 20000000;
        background-color: rgba(0, 0, 0, 0.25);
    }
}

.modal{
    z-index: 100000;
    .modal-content {
        border-radius: $modal-border-radius;
        border: none;
    }
    .modal-header,.modal-footer {
        padding-right:$modal-header-padding-x;
        padding-left:$modal-header-padding-x;
        padding-top:$modal-header-padding-y;
        padding-bottom:$modal-header-padding-y;
    }
    .modal-body {
        padding-right:$modal-body-padding-x;
        padding-left:$modal-body-padding-x;
        padding-top:$modal-body-padding-y;
        padding-bottom:$modal-body-padding-y;
    }
    &.p-0 {
        .modal-content>.modal-body {
            padding: 0;
        }
    }
}

$side-width: 66%;
.dark {
    .inline-modal {
        background: $modal-bg-dark;
        box-shadow:rgba(0, 0, 0, 0.25) 0px 1px 6px 0px, rgba(0, 0, 0, 0.25) 0px 1px 4px 0px;
    }
    .modal-content {
        box-shadow:rgba(0, 0, 0, 0.25) 0px 1px 6px 0px, rgba(0, 0, 0, 0.25) 0px 1px 4px 0px;
        background: $modal-bg-dark;
        color: $modal-content-color-dark;
    }
}

.side-modal {
    overflow-y: hidden !important;
    opacity: 1 !important;
    z-index: 10000000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    .modal-body {
        padding: 0;
        height: calc(100% - 60px);
        overflow-y: auto;
    }

    .modal-dialog {
        box-shadow: rgba(75, 75, 75, 0.11) 0px 1px 6px 0px, rgba(75, 75, 75, 0.11) 0px 1px 4px 0px;
        transition: transform .2s ease-out;
        transform: translate(100%, 0%) !important;
        position: absolute;
        max-width: 1000px;
        width: $side-width !important;
        height: 100% !important;
        max-height: 100% !important;
        margin: 0;
        right: 0;
        left: auto;
    }

    &.show {
        .modal-dialog {
            transform: translate(0%, 0%) !important;
        }
    }

    &__footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 20px;
        height: 130px;
    }

    .modal-content {
        border-radius: 0;
        width: 100%;
        height:100%;
    }
    &.create-feature-modal {
        .modal-body {
            overflow-y: hidden;
        }
        &.overflow-y-auto {
            .modal-body {
                overflow-y: auto;
            }
        }
        .tabs {
            .tab-item {
                margin-top: 16px;
                overflow-y: auto;
                height: calc(100vh - 110px);
            }
        }
        .create-feature-tab {
            margin-top: 16px;
            overflow-y: auto;
            height: calc(100vh - 80px);
        }
    }

}

.disable-transitions {
  .modal-dialog {
    transition: none !important;
  }
}

.inline-modal {
    position: absolute;
    background: $body-bg;
    border-radius: $border-radius;
    z-index: 1;
    box-shadow:rgba(75, 75, 75, 0.11) 0px 1px 6px 0px, rgba(75, 75, 75, 0.11) 0px 1px 4px 0px;
    border: 1px solid $input-border-color;
    &__title {
        line-height: 40px;
    }
}

.inline-modal--tags {
    width: 350px;
    input.input {
        border: 1px solid $input-border-color !important;
    }
}

.modal-back-btn {
    color: $bt-brand-primary;
    position: absolute;
    left:10px;
    top:0;
    background-color: transparent !important;
    border: none !important;
}

.modal-backdrop {
    bottom: 0;
}

.modal-open {
    position: relative;
    padding-right: 0 !important;
    overflow: hidden;
}

.modal.modal-confirm {
    z-index: 200000000000;
    h3 {
        margin-bottom: 0;
    }
}

.modal-open #crisp-chatbox [data-chat-status="initial"] {
    display: none !important;
}

@media (max-width: 600px) { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .side-modal__footer {
        position: relative !important;
    }
    .side-modal #create-feature-modal {
        height: 100%;
    }
}

.create-segment-modal.modal-open,.create-segment-modal .modal-body {
    overflow-y: hidden !important;
    .tab-item {
        height: calc(100vh - 100px);
        overflow-y: auto;
    }
}
