.tag {
    line-height: 18px;
    border-radius: $border-radius;
    color: white;
    font-weight: bold;
    transition: all 300ms;
    cursor: pointer;
    &--select {
        .chip-icon {
            margin-left: 5px;
        }
        width: 54px;
        line-height: 32px;
        height: 32px;
        user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
    }
}
.tag-filter {
    .tag-filter-list {
        gap: 8px;
    }
    .tag {
        opacity: 0.75;
        min-width: 100px;
        text-align: center;
        .flex-row {
            justify-content: center;
        }
        padding-bottom: 0.2em !important;
        padding-top: 0.2em !important;
        padding-left: 1em !important;
        padding-right: 1em !important;
        &.selected {
            opacity: 1;
        }
        .icon {
            margin-left: 5px;
        }
    }
}
.tag-values {
    .tag {
        margin-right: 0.5em;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
        height: 21px;
        width: 42px;
        padding-left: 0 !important;
        padding-right: 0 !important;
        .icon {
            display: none;
        }
    }
}
.tag-list {
    max-height: 250px;
    overflow-y: auto;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    &:last-child {

    }
}

.dark {
    .tag {
        border-color: transparent;
    }
}
